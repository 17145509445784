import { useEffect } from "react";
import {
  StorageKey,
  initializeAdCampaign,
  initializeReferrer,
} from "@/utils/storage";
import { publicConfig } from "@/PublicConfig";
import { useIntercom } from "react-use-intercom";

interface ProxyProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function Proxy(props: ProxyProps) {
  const intercom = useIntercom();

  useEffect(() => {
    if (!publicConfig.stage.isProd) {
      sessionStorage.setItem(StorageKey.doNotTrack, "1");
    }

    initializeReferrer();
    initializeAdCampaign();

    intercom.boot();
  }, [intercom]);

  return <div {...props} />;
}
