import {
  makePublicConfig,
  PublicConfig,
  setPublicConfig,
} from "@webmonitoring/vp-common/Config/PublicConfig";
import { Stage, StageType } from "@webmonitoring/vp-common/Config/Stage";

type Config = Readonly<PublicConfig & StageConfig>;

type StageConfig = {
  robots: string;
};

const vpCommonConfig = makePublicConfig({
  appName: "Public Pages Service",
  // https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser
  stage: Stage.fromParts(
    <StageType>process.env["NEXT_PUBLIC_STAGE_TYPE"] ?? StageType.Local
  ),
});

export const publicConfig: Config = {
  ...vpCommonConfig,
  ...makeStageConfig(vpCommonConfig.stage),
};

setPublicConfig(publicConfig);

function makeStageConfig(stage: Stage): StageConfig {
  switch (stage.type) {
    case StageType.Local:
      return {
        robots: "none",
      };

    case StageType.Develop:
      return {
        robots: "none",
      };

    case StageType.Prod:
      return {
        robots: "all",
      };

    default:
      throw new Error("Unexpected stage");
  }
}
