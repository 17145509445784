// import "@webmonitoring/vp-common/tracing";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "@/globals.css";
import "@/globals";
import { AppProps } from "next/app";
import Script from "next/script";
import localFont from "@next/font/local";
import classNames from "classnames";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import { publicConfig } from "@/PublicConfig";
import { IntercomProvider } from "react-use-intercom";
import Proxy from "./Proxy";

// See: https://nextjs.org/docs/basic-features/font-optimization
const font = localFont({
  src: "./satoshi-variable.woff2",
  variable: "--font-satoshi",
});

// See: https://fontawesome.com/docs/web/use-with/react/use-with#next-js
config.autoAddCss = false;

// See: https://nextjs.org/docs/pages/building-your-application/optimizing/analytics#web-vitals
export const reportWebVitals = (metric: any) => {
  if (metric.label === "web-vital") {
    // console.log(metric)
  }
};

// Any next/script Script components must be outside the head element.
export default function App({ Component, pageProps }: AppProps) {
  return (
    <main className={classNames("font-sans", font.variable)}>
      <ErrorBoundary>
        <IntercomProvider appId={publicConfig.intercomAppId}>
          <Proxy>
            <Component {...pageProps} />
          </Proxy>
        </IntercomProvider>
        <div id="visualping-portal-root">
          <div />
        </div>
        <Script
          dangerouslySetInnerHTML={{
            __html: `!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
  e.load("211nTKp5iC53LNxpuaDYK3zjsGQ","https://visualpingjzt.dataplane.rudderstack.com"),
  e.page()}();`,
          }}
          id="rudder-stack-analytics-script"
          type="text/javascript"
        />
      </ErrorBoundary>
    </main>
  );
}
