import { LegacyJobPreAction } from "@webmonitoring/vp-common/Types/BaseTypes";
import { EventCategory } from "@webmonitoring/vp-common/EventTracker";
import { track, TrackProperties } from "../utils";

// See: https://martinfowler.com/articles/domain-oriented-observability.html

const SignUpEventName = {
  jobSearchOpenPageInBrowser: "jobSearchOpenPageInBrowser",
  jobSearchRetry: "jobSearchRetry",
  jobSearchStart: "jobSearchStart",
  jobSearchURLValidation: "jobSearchURLValidation",
  signUpStart: "signUpStart",
  reportAProblem: "reportAProblem",
} as const;

interface JobSearchOpenPageInBrowserOptions extends TrackProperties {
  url?: string;
}

export const jobSearchOpenPageInBrowser = (
  properties?: JobSearchOpenPageInBrowserOptions
) => {
  track({
    category: EventCategory.SIGNUP,
    name: SignUpEventName.jobSearchOpenPageInBrowser,
    properties,
  });
};

interface JobSearchRetryOptions extends TrackProperties {
  url?: string;
}

export const jobSearchRetry = (properties?: JobSearchRetryOptions) => {
  track({
    category: EventCategory.SIGNUP,
    name: SignUpEventName.jobSearchRetry,
    properties,
  });
};

interface JobSearchStartOptions extends TrackProperties {
  url?: string;
}

export const jobSearchStart = (properties?: JobSearchStartOptions) => {
  track({
    category: EventCategory.SIGNUP,
    name: SignUpEventName.jobSearchStart,
    properties,
  });
};

interface JobSearchURLValidationOptions extends TrackProperties {
  url?: string;
}

export const jobSearchURLValidation = (
  properties?: JobSearchURLValidationOptions
) => {
  track({
    category: EventCategory.SIGNUP,
    name: SignUpEventName.jobSearchURLValidation,
    properties,
  });
};

interface SignUpStartOptions {
  actions?: [];
  compare_type?: string;
  frequency?: string;
  threshold?: string;
  URL?: string;
}

export const signUpStart = (properties?: SignUpStartOptions) => {
  track({
    category: EventCategory.SIGNUP,
    name: SignUpEventName.signUpStart,
    properties: {
      ...properties,
      actions: properties?.actions
        ?.flatMap((action) => Object.keys(action))
        .toString(),
      has_actions: Boolean(properties?.actions?.length),
    },
  });
};

interface ReportAProblemOptions extends TrackProperties {
  url?: string;
}

export const reportAProblem = (properties?: ReportAProblemOptions) => {
  track({
    category: EventCategory.SIGNUP,
    name: SignUpEventName.reportAProblem,
    properties,
  });
};
