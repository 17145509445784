import { EventCategory } from "@webmonitoring/vp-common/EventTracker";
import { track } from "../utils";

// See: https://martinfowler.com/articles/domain-oriented-observability.html

const NavigationEventName = {
  renderSystemErrorPage: "renderSystemErrorPage",
} as const;

export const renderSystemErrorPage = () => {
  track({
    category: EventCategory.NAVIGATION,
    name: NavigationEventName.renderSystemErrorPage,
  });
};
