import { track } from "@/track";
import classNames from "classnames";
import Image from "next/image";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";

interface ErrorPageProps extends React.HTMLAttributes<HTMLDivElement> {}

const ErrorPage = ({ className, ...props }: ErrorPageProps) => {
  useEffect(() => {
    track.navigation.renderSystemErrorPage();
  }, []);

  return (
    <div
      className={twMerge(classNames("h-[100vh] w-full", className))}
      {...props}
    >
      <main className="flex h-[100vh] w-full items-center justify-center">
        <div className="flex items-center justify-center">
          <div className="w-1/2 space-y-6">
            <h1 className="text-4xl font-bold">Oops!</h1>
            <p className="text-xl">
              We can&apos;t seem to show this page at the moment,{" "}
              <span className="font-semibold">please try again later.</span>
            </p>
          </div>
          <Image
            alt="Error Illustration"
            className="hidden md:flex md:w-[350px]"
            height="238"
            src="/_public-pages-service/laptop-hand-up-illustration.svg"
            width="350"
          />
        </div>
      </main>
    </div>
  );
};

export default ErrorPage;
