import { toJson } from "./json";

export const StorageKey = {
  ad_campaign: "ad_campaign",
  doNotTrack: "dnt",
  fastshotLiveSnapsEnabled: "fastshotLiveSnapsEnabled",
  referrer: "referrer",
} as const;

interface AdCampaignResult {
  // The ad campaign object can contain unknown fields
  [index: number | string]: string | undefined;
  landing_page?: string;
}

export const getAdCampaign = () => {
  const value = sessionStorage.getItem(StorageKey.ad_campaign);

  return toJson<AdCampaignResult>(value);
};

export const getReferrer = () => {
  const value = sessionStorage.getItem(StorageKey.referrer);

  return value ?? "unknown";
};

export const initializeReferrer = () => {
  const referrer = sessionStorage.getItem(StorageKey.referrer);

  if (referrer) {
    return;
  }

  const value = document.referrer === "" ? "direct" : document.referrer;

  sessionStorage.setItem(StorageKey.referrer, value);
};

export const initializeAdCampaign = () => {
  const adCampaign = getAdCampaign() ?? {};

  if (!adCampaign.landing_page) {
    adCampaign.landing_page = window.location.pathname;
  }

  const params = new URLSearchParams(window.location.search);

  const value = {
    ...adCampaign,
    ...Object.fromEntries(params),
  };

  sessionStorage.setItem(StorageKey.ad_campaign, JSON.stringify(value));
};
