import { PureComponent } from "react";
import ErrorPage from "./ErrorPage";

type ErrorBoundaryProps = React.HTMLAttributes<HTMLDivElement>;

interface ErrorBoundaryState {
  error?: boolean;
}

class ErrorBoundary extends PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { error: false };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  override render() {
    return this.state.error ? <ErrorPage /> : this.props.children;
  }
}

export default ErrorBoundary;
