import { StorageKey } from "@/utils/storage";
import { EventInfo, eventTracker } from "@webmonitoring/vp-common/EventTracker";

export type TrackProperties = EventInfo["properties"] & {
  error?: string;
  event_location?: string;
  initiator?: string;
  is_org_event?: boolean;
  response?: string;
};

export interface TrackOptions extends EventInfo {
  properties?: TrackProperties;
}

export const track = ({ properties, ...options }: TrackOptions) => {
  const doNotTrack = sessionStorage.getItem(StorageKey.doNotTrack);

  if (doNotTrack) {
    return;
  }

  eventTracker().track(
    {
      properties: {
        event_location: window.location.pathname,
        ...properties,
      },
      ...options,
    },
    undefined
  );
};

export const toStringArrayFields = (value?: {}) => {
  return Object.entries(value ?? {}).reduce((result, [key, value]) => {
    return {
      ...result,
      [key]: Array.isArray(value) ? value.toString() : value,
    };
  }, {});
};
